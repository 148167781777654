(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/jackpot-and-funds/assets/javascripts/jackpot-and-funds.js') >= 0) return;  svs.modules.push('/components/lb-ui/jackpot-and-funds/assets/javascripts/jackpot-and-funds.js');
"use strict";


const {
  SpeechBubble,
  SpeechBubbleSport1,
  SpeechBubbleSport2,
  SpeechBubbleSport3
} = svs.ui.ReactSpeechBubble;
const {
  fundsConstants
} = svs.components.lbUtils.funds;
const formatUtils = svs.utils.format;
const {
  helpers,
  productIds
} = svs.utils.products;
const constants = {
  JACKPOT: 'jackpot',
  FUND: 'fund'
};

const _formatJackpotAndFunds = _ref => {
  let {
    productId,
    drawNumber,
    jackpots,
    funds
  } = _ref;
  let jackpotAmountString;
  if (jackpots.length > 0) {
    const currentJackpot = drawNumber ? jackpots.find(jackpot => parseInt(jackpot.drawNumber, 10) === parseInt(drawNumber, 10)) : jackpots.find(jackpot => parseInt(jackpot.productId, 10) === parseInt(productId, 10));
    if (currentJackpot && currentJackpot.jackpotAmountString && !currentJackpot.defaultJackpot) {
      return {
        type: constants.JACKPOT,
        text: 'Jackpot!'
      };
    }
  }
  if (!jackpotAmountString && funds.length > 0) {
    let currentFund;
    if (helpers.isTopptipset(productId)) {
      const topptipsetIds = [productIds.T8, productIds.T8STRYK, productIds.T8EXTRA];
      currentFund = drawNumber ? funds.find(fund => parseInt(fund.drawNumber, 10) === parseInt(drawNumber, 10)) : funds.find(fund => topptipsetIds.includes(fund.productId));
    } else {
      currentFund = drawNumber ? funds.find(fund => parseInt(fund.drawNumber, 10) === parseInt(drawNumber, 10)) : funds.find(fund => parseInt(fund.productId, 10) === parseInt(productId, 10));
    }
    if (currentFund) {
      const text = currentFund.type === fundsConstants.FUND_TYPE_ROLLOVER ? 'Rullpott!' : 
      "".concat(formatUtils.Currency(currentFund.fundsAmount, false), " kr extra!");
      return {
        type: constants.FUND,
        text,
        typeOfFund: currentFund.type
      };
    }
  }
};

const JackPotAndFunds = _ref2 => {
  let {
    productId,
    drawNumber,
    speechBubbleSize = '100',
    jackpots = [],
    funds = []
  } = _ref2;
  const jackpotFunds = _formatJackpotAndFunds({
    productId,
    drawNumber,
    jackpots,
    funds
  });
  if (!jackpotFunds) {
    return null;
  }
  return React.createElement("div", {
    "data-testid": "qa-jackpot-and-funds"
  }, jackpotFunds.type === constants.JACKPOT && React.createElement("div", {
    className: "jackpot"
  }, React.createElement(SpeechBubble, {
    size: speechBubbleSize
  }, React.createElement(SpeechBubbleSport1, null, jackpotFunds.text))), jackpotFunds.type === constants.FUND && React.createElement("div", {
    className: "fund"
  }, jackpotFunds.typeOfFund === fundsConstants.FUND_TYPE_ROLLOVER && React.createElement(SpeechBubble, {
    size: speechBubbleSize
  }, React.createElement(SpeechBubbleSport2, null, jackpotFunds.text)), jackpotFunds.typeOfFund === fundsConstants.FUND_TYPE_ADJUST && React.createElement(SpeechBubble, {
    size: speechBubbleSize
  }, React.createElement(SpeechBubbleSport3, null, jackpotFunds.text))));
};
setGlobal('svs.components.lbUi.jackpotAndFunds.JackPotAndFunds', {
  JackPotAndFunds
});

 })(window);